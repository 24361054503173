import React from 'react';
import {Link} from 'gatsby';
import styled from 'styled-components';
import Layout from '@common/Layout';
import DrobeboxLogo from '@static/logo_white.png';
import DrobeboxLogoBlack from '@static/drobebox_logo.png';
import BigVisual from '@static/secondchance/big_visual.png';
import Title from '@static/secondchance/title.png';
import ShopButton from '@static/secondchance/shop_button.png';
import Background from '@static/secondchance/background.png';
import { OutboundLink } from 'gatsby-plugin-google-analytics'
import Tote from '@static/secondchance/tote.jpg';
import LuckyDraw from '@static/secondchance/luckdraw.png';
import Coin from '@static/secondchance/coin.png';
import FreeMember from '@static/secondchance/free_member.png';
import SubTitle from '@static/secondchance/sub_title.png';
import Benefit1 from '@static/secondchance/benefit_1.png';
import Benefit2 from '@static/secondchance/benefit_2.png';
import Benefit3 from '@static/secondchance/benefit_3.png';
import Benefit4 from '@static/secondchance/benefit_4.png';
import Brand from '@static/secondchance/brand.jpg';
import App from '@static/secondchance/app.png';
import Cleaning from '@static/secondchance/cleaning.png';
import Return from '@static/secondchance/return.png';

const targetDate = new Date(2020, 10, 21, 0, 0).getTime();
const currentDate = Date.now();
function pad(n) {
  return (n < 10 ? '0' : '') + n;
}
let days, hours, minutes;
let secondsLeft = ((targetDate - currentDate) / 1000);
days = pad( Math.floor( secondsLeft / 86400 ) );
secondsLeft %= 86400;
hours = pad( Math.floor( secondsLeft / 3600 ) );
secondsLeft %= 3600;
minutes = pad( Math.floor( secondsLeft / 60 ) );
const IndexPage = () => (
  <Layout>
    <Header>
      <Link to='/'>
        <Logo src={DrobeboxLogo} alt="Logo" />
      </Link>
    </Header>
    <Container>
      <Card>
        <div style={{flex: 3, padding: '12px'}}>
          <img src={BigVisual} style={{width: '100%'}}/>
        </div>
        <div style={{flex: 4,display: 'flex', flexDirection: 'column'}}>
          <TitleVisual src={Title} />
          <h2>Với chất lượng đồ cửa hàng đầu tiên</h2>
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', paddingBottom: '2rem'}}>
            <h2 style={{paddingRight: '2rem'}}>tại</h2>
            <img style={{width: 150}} src={DrobeboxLogoBlack}/>
          </div>
          <p>Bạn có biết</p>
          <p>... mỗi chiếc quần jeans cần khoảng 3.000 lít nước và 6kg khí thải CO2 để sản xuất</p>
          <p>... mỗi ngày có ít nhất 2.500 chiếc quần jeans được sản xuất trên toàn thế giới ?</p>
          <p>đa phần các sản phẩm này đều bị lãng phí, chỉ được sử dụng 1-2 lần và vô tình làm gia tăng áp lực sản xuất mới cho Trái Đất</p><br/>
          <p>Chúng ta đang có quá nhiều sự lựa chọn nhưng Trái Đất thì không!</p>
          <p>Cùng Drobebox thêm lần nữa gieo hạt mầm sống cho Trái Đất bằng những lựa chọn trang phục 2Hand tái sử dụng với chất lượng như mua mới tại cửa hàng để giảm thiểu ảnh hưởng của "Rác thải thời trang"</p>
          <CountDown>
            <p>Chỉ còn lại </p>
            <h3>{`${days} ngày ${hours} giờ ${minutes} phút`}</h3>
            <p>để chung tay bảo vệ Trái Đất</p>
          </CountDown>
          <ButtonContainer>
            <h4>BẠN ĐÃ SẴN SÀNG HÀNH ĐỘNG ?</h4>
            <OutboundLink href={`https://app.drobebox.com/getapp`}>
              <img src={ShopButton}/>
            </OutboundLink>
          </ButtonContainer>
        </div>
      </Card>
      <Card style={{flexDirection: 'column', paddingTop: 0}}>
        <div style={{display: 'flex', justifyContent:'center'}}>
          <ShareToFriend>CHIA SẺ NGAY CHO BẠN BÈ</ShareToFriend>
        </div>
        <div style={{display: 'flex', justifyContent:'center', padding: '1rem'}}>
          <h2 style={{color: '#ffc106', textAlign: 'center'}}>TIẾT KIỆM CÀNG NHIỀU - MỞ KHOÁ CÀNG MÊ</h2>
        </div>
        <SubTitleContainer>
          <p style={{textAlign: 'center'}}>Mỗi sản phẩm bạn mua sẽ được quy đổi sang số lượng nước & khí thải CO2 tiết kiệm được cho môi trường. TẤT CẢ ĐƠN HÀNG từ 06/11 sẽ được cộng dồn để mở khoá các phần quà bên dưới. Nàng sẽ nhận được TOÀN BỘ các phần quà được mở khoá vào cuối chương trình.<span style={{color: '#83b161', fontWeight: 500}}>TỔNG GIÁ TRỊ LÊN ĐẾN 500 TRIỆU ĐỒNG</span></p>
          <BenefitContainer>
            <Benefit>
              <RewardTitle>
                <h6>Túi Tote</h6>
              </RewardTitle>
              <BenefitCard style={{backgroundColor: '#ebebeb'}}>
                <img src={Tote} style={{opacity: 0.3}}/>
              </BenefitCard>
              <h6>Cần 20 đơn hàng để mở khoá</h6>
            </Benefit>
            <Benefit>
              <RewardTitle>
                <h6>2 lượt tham gia vòng quay may mắn</h6>
              </RewardTitle>
              <BenefitCard style={{backgroundColor: '#ebebeb'}}>
                <img src={LuckyDraw} style={{opacity: 0.3}}/>
              </BenefitCard>
              <h6>Cần 60 đơn hàng để mở khoá</h6>
            </Benefit>
            <Benefit>
              <RewardTitle>
                <h6>100 XU = 300k</h6>
                <p>MUA HÀNG/ DỊCH VỤ TRÊN DROBEBOX</p>
              </RewardTitle>
              <BenefitCard style={{backgroundColor: '#ebebeb'}}>
                <img src={Coin} style={{opacity: 0.3}}/>
              </BenefitCard>
              <h6>Cần 150 đơn hàng để mở khoá</h6>
            </Benefit>
            <Benefit>
              <RewardTitle>
                <h6>MIỄN PHÍ 1 THÁNG</h6>
                <p>GÓI KHÁM PHÁ 999K TẠI DROBEBOX</p>
              </RewardTitle>
              <BenefitCard style={{backgroundColor: '#ebebeb'}}>
                <img src={FreeMember} style={{opacity: 0.3}}/>
              </BenefitCard>
              <h6>Cần 500 đơn hàng để mở khoá</h6>
            </Benefit>
          </BenefitContainer>
        </SubTitleContainer>
      </Card>
      <Card>
        <SubTitleContainer>
          <SubTitleVisual src={SubTitle}/>
          <h2>TẠI DROBEBOX CÓ GÌ ĐỘC ĐÁO</h2>
          <BenefitContainer>
            <Benefit>
              <BenefitTitle src={Benefit1}/>
              <p>TỪ CÁC THƯƠNG HIỆU THỜI TRANG THIẾT KẾ CAO CẤP</p>
              <BenefitCard>
                <img src={Brand}/>
              </BenefitCard>
            </Benefit>
            <Benefit>
              <BenefitTitle src={Benefit2}/>
              <p>TÌNH TRẠNG SẢN PHẨM & SỐ LẦN ĐÃ QUA SỬ DỤNG</p>
              <BenefitCard>
                <img src={App}/>
              </BenefitCard>
            </Benefit>
            <Benefit>
              <BenefitTitle src={Benefit3}/>
              <p>TOÀN BỘ VÒNG ĐỜI ĐƯỢC XỬ LÝ CHUYÊN NGHIỆP</p>
              <BenefitCard>
                <img src={Cleaning}/>
              </BenefitCard>
            </Benefit>
            <Benefit>
              <BenefitTitle src={Benefit4}/>
              <p>30 NGÀY HOÀN TIỀN KHÔNG CẦN LÝ DO</p>
              <BenefitCard>
                <img src={Return}/>
              </BenefitCard>
            </Benefit>
          </BenefitContainer>
        </SubTitleContainer>
      </Card>
      <GoToAppContainer>
        <AppLink href={`https://app.drobebox.com/getapp`}>MỞ ỨNG DỤNG</AppLink>
      </GoToAppContainer> 
    </Container>
  </Layout>

);

const Header = styled.div`
  width: 100vw;
  background: black;
  display: flex;
  justify-content: center;
`

const Logo = styled.img`
  max-height: 100px;
`

const Container = styled.div`
  display: flex;
  width: 100vw;
  flex-direction: column;
  position: relative;
  align-items: center;
  padding: 10vw;
  background-image: url(${Background});
`

const Card = styled.div `
  background: white;
  display: flex;
  box-shadow: 1px 1px 10px 0px rgba(50, 50, 50, 0.55);
  border-radius: 8px;
  width: 100%;
  padding: 1rem;
  flex-direction: row;
  p {
    font-weight: 300;
    margin-bottom: 0;
    font-size: 18px;
  }
  @media (max-width: ${props => props.theme.screen.xs}) {
    flex-direction: column;
    align-items: center;
  }
  margin-top: 1rem;
  margin-bottom: 1rem;
`

const TitleVisual = styled.img `
  width: 100%;
  padding-right: 2rem;
  @media (max-width: ${props => props.theme.screen.xs}) {
    padding-right: 0;
  }
`

const CountDown = styled.div `
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-end;
  p {
    font-weight: 500;
    margin-bottom: 0.5rem;
  }
  h3 {
    padding-left: 1rem;
    padding-right: 1rem;
    color: #83b161;
    margin-bottom: 0rem;
    line-height: 1.7;
  }
`

const ButtonContainer = styled.div `
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 1rem;
  h4 {
    margin-bottom: 0
  }
  img {
    width: 200px;
    padding-left: 2rem;
  }
  @media (max-width: ${props => props.theme.screen.xs}) {
    flex-direction: column;
    align-items: center;
    h4 {
      text-align: center;
    }
    img {
      padding-left: 0;
    }
  }
`
const SubTitleContainer = styled.div `
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
`

const SubTitleVisual = styled.img `
  width: 50%;
  min-width: 300px;
  margin-bottom: 1rem;
`

const BenefitContainer = styled.div `
  padding-top: 2rem;
  display: flex;
  flex-direction: row;
  width: 100%;
  @media (max-width: ${props => props.theme.screen.xs}) {
    flex-direction: column;
    align-items: center;
  }
`

const Benefit = styled.div `
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 2rem;
  p {
    font-size: 14px;
  }
  h6 {
    padding-top: 1rem;
    text-align: center;
  }
`
const BenefitCard = styled.div `
  margin-top: 1rem;
  background-color: #f9e9c4;
  border-color: #828180;
  border-width: 1px;
  border-radius: 8px;
  box-shadow: 4px 4px 5px 0px rgba(50, 50, 50, 0.75);
  img {
    width: 100%;
    padding: 1rem;
  }
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`
const BenefitTitle = styled.img `
  width: 200px;
`

const ShareToFriend = styled.h4 `
  padding: 10px;
  background: #83b161;
  color: white;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  @media (max-width: ${props => props.theme.screen.xs}) {
    text-align: center;
    font-size: 18px;
  }
`

const RewardTitle = styled.h6 `
  padding: 10px;
  background: #83b161;
  border-radius: 8px;
  box-shadow: 4px 4px 5px 0px rgba(50, 50, 50, 0.75);
  height: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h6 {
    text-transform: uppercase;
    text-align: center;
    color: white;
    margin-bottom: 0;
  }
  p {
    color: white;
    font-size: 10px;
    line-height: 10px;
  }
}
`
const GoToAppContainer = styled.div`
  display: none;
  width: 100vw;
  flex: 1;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 300;
  padding: 1rem;
  background: white;
  a:hover {
    color: white;
  }
  box-shadow: inset 0 1px #fff, 0 1px 3px rgba(34,25,25,0.4);
  @media (max-width: ${props => props.theme.screen.xs}) {
    display: flex;
  }
`

const AppLink = styled(OutboundLink)`
  z-index: 200;
  width: 300px;
  height: 60px;
  background: black;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border-radius: 8px;
`
export default IndexPage;
